<template>
  <Header />
  <Banner />
  <v-main class="main_body">
    <div style="width: 80%; margin: 0 auto">
      <div class="d-flex justify-center" style="margin-bottom: 24px">
        <span
          style="
            text-shadow: 2px 2px 2px #a852e1;
            border-left: 6px solid #7f69eb;
            padding-left: 1.2rem;
            font-size: 30px;
            color: #fff;
            display: inline;
            margin: 0 auto;
          "
          >核心价值
        </span>
      </div>

      <v-container>
        <v-row>
          <v-col lg="4" xl="4" xs="12" sm="12">
            <v-card
              style="
                background-color: #552eaf;
                margin-right: 14px;
                margin-left: 14px;
              "
            >
              <div class="d-flex align-center justify-space-around">
                <v-btn
                  icon
                  style="height: 100px; width: 100px; margin-top: 24px"
                  color="#301a61"
                  to="../Ecological"
                  ><v-icon
                    size="64px"
                    color="white"
                    icon="mdi-link-variant"
                    dark
                  ></v-icon
                ></v-btn>
              </div>
              <v-card-title
                class="d-flex align-center justify-space-around card-text-shadow"
                style="height: 64px"
                >生态联结</v-card-title
              >
              <div style="margin-bottom: 24px; text-align: center">
                <span class="card-text-shadow"
                  >竞赛组织&emsp; 论坛研讨&emsp; 产学合作</span
                >
              </div>
            </v-card>
          </v-col>
          <v-col lg="4" xl="4" xs="12" sm="12">
            <v-card
              style="
                background-color: #552eaf;
                margin-left: 14px;
                margin-right: 14px;
              "
            >
              <div class="d-flex align-center justify-space-around">
                <v-btn
                  icon
                  style="height: 100px; width: 100px; margin-top: 24px"
                  color="#301a61"
                  to="../ResourceConstruction"
                  ><v-icon
                    size="64px"
                    color="white"
                    icon="mdi-handshake"
                    dark
                  ></v-icon
                ></v-btn>
              </div>
              <v-card-title
                class="d-flex align-center justify-space-around"
                style="
                  height: 64px;
                  font-weight: bold;
                  text-shadow: 1.5px 1.5px 1.5px black;
                  color: white;
                "
                >资源共建</v-card-title
              >
              <div style="margin-bottom: 24px; text-align: center">
                <span class="card-text-shadow"
                  >课程资源&emsp; 产业案例&emsp; 人才挖掘</span
                >
              </div>
            </v-card>
          </v-col>
          <v-col lg="4" xl="4" xs="12" sm="12">
            <v-card
              style="
                background-color: #552eaf;
                margin-left: 14px;
                margin-right: 14px;
              "
            >
              <div class="d-flex align-center justify-space-around">
                <v-btn
                  icon
                  style="height: 100px; width: 100px; margin-top: 24px"
                  color="#301a61"
                  to="/TechnologyEmpowerment"
                  ><v-icon
                    size="64px"
                    color="white"
                    icon="mdi-brain"
                    dark
                  ></v-icon
                ></v-btn>
              </div>
              <v-card-title
                class="d-flex align-center justify-space-around"
                style="
                  height: 64px;
                  font-weight: bold;
                  text-shadow: 1.5px 1.5px 1.5px black;
                  color: white;
                "
                >技术赋能</v-card-title
              >
              <div style="margin-bottom: 24px; text-align: center">
                <span class="card-text-shadow"
                  >科研合作&emsp; 专家咨询&emsp; 行业培训</span
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div style="height: 80px"></div>
      </v-container>

      <div class="d-flex justify-center" style="margin-bottom: 24px">
        <span
          style="
            text-shadow: 2px 2px 2px #a852e1;
            border-left: 6px solid #7f69eb;
            padding-left: 1.2rem;
            font-size: 30px;
            color: #fff;
            display: inline;
            margin: 0 auto;
          "
          >生态伙伴
        </span>
      </div>
      <v-container v-if="!isMobile">
        <v-row>
          <v-col v-for="(item, index) in cooperative.slice(0, 5)" :key="index">
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, index) in cooperative.slice(5, 10)" :key="index">
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in cooperative.slice(10, 15)"
            :key="index"
          >
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <div style="height: 120px"></div>
      </v-container>
      <v-container v-if="isMobile">
        <v-row>
          <v-col v-for="(item, index) in cooperative.slice(0, 3)" :key="index">
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, index) in cooperative.slice(3, 6)" :key="index">
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, index) in cooperative.slice(6, 9)" :key="index">
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, index) in cooperative.slice(9, 12)" :key="index">
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in cooperative.slice(12, 15)"
            :key="index"
          >
            <v-img
              aspect-ratio="16/9"
              cover
              :src="require('../assets/img/cooperation-logo/' + item.imgSrc)"
            ></v-img>
          </v-col>
        </v-row>
        <div style="height: 30px"></div>
      </v-container>
    </div>
  </v-main>
  <IndexFooter />
</template>

<script>
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
// Components
import Header from "../components/Header.vue";
import Banner from "../components/Banner.vue";
import IndexFooter from "../components/IndexFooter.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    Header,
    Banner,
    IndexFooter,
  },
  data() {
    return {
      isMobile: false,
      cooperative: [
        {
          name: "百度",
          imgSrc: "1.png",
        },
        {
          name: "北信科",
          imgSrc: "2.png",
        },
        {
          name: "高等教育出版社",
          imgSrc: "3.png",
        },
        {
          name: "海康威视",
          imgSrc: "4.png",
        },
        {
          name: "华为",
          imgSrc: "5.png",
        },
        {
          name: "南工大",
          imgSrc: "6.png",
        },
        {
          name: "OPPO",
          imgSrc: "7.png",
        },
        {
          name: "徐工集团",
          imgSrc: "8.png",
        },
        {
          name: "信息技术新工科产学研联盟",
          imgSrc: "9.png",
        },
        {
          name: "英特尔",
          imgSrc: "10.png",
        },
        {
          name: "中国航天科技集团公司",
          imgSrc: "11.png",
        },
        {
          name: "中国商飞上海飞机制造有限公司",
          imgSrc: "12.png",
        },
        {
          name: "浙江大学计算机",
          imgSrc: "13.png",
        },
        {
          name: "浙江大学教育",
          imgSrc: "14.png",
        },
        {
          name: "中科软科技股份有限公司",
          imgSrc: "15.png",
        },
      ],
    };
  },
  mounted() {
    const { name } = useDisplay();
    this.isMobile = name.value == "xs";
  },
});
</script>

<style>
/* .value_card {
  margin-left: 20%;
  margin-right:
} */
.main_body {
  background-image: linear-gradient(
    to right,
    rgb(48, 22, 88),
    rgb(48, 39, 131)
  );
}

.card-text-shadow {
  font-weight: bold;
  text-shadow: 1.5px 1.5px 1.5px black;
  color: white;
}

.moHomepage_moTutorIntroWrapper-3SH6q {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.index_wrapper-25RmM {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.index_content-2yZOg {
  position: relative;
  z-index: 1;
  width: 374px;
  height: 325px;
}

.index_contentImg-13vsC {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 226px;
  height: 226px;
}

.index_circle-1YKhn {
  position: absolute;
  border-radius: 50%;
}

.index_card-3CzQt {
  -webkit-animation: index_bubble-2i-Hs 2s infinite ease-in-out;
  animation: index_bubble-2i-Hs 2s infinite ease-in-out;
  box-shadow: 0px 6px 16px 0px rgb(7 67 128 / 5%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, sans-serif;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 22px;
  border-radius: 6px;
  -o-border-image: linear-gradient(337deg, rgba(255, 255, 255, 0), #ffffff) 1 1;
  border-image: linear-gradient(337deg, rgba(255, 255, 255, 0), #ffffff) 1 1;
  padding: 6px 21px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
}

.subtitle {
  box-shadow: 0px 6px 16px 0px rgb(7 67 128 / 5%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, sans-serif;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 22px;
  border-radius: 6px;
  -o-border-image: linear-gradient(337deg, rgba(255, 255, 255, 0), #ffffff) 1 1;
  border-image: linear-gradient(337deg, rgba(255, 255, 255, 0), #ffffff) 1 1;
  padding: 6px 21px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
}

.index_blurBox-2srzE {
  position: absolute;
  left: 0px;
  top: 7px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: -1;
  background: rgba(255, 255, 255, 0.1);
}

@-webkit-keyframes index_bubble-2i-Hs {
  0% {
    transform: translateY(0px) scale(1);
    transform-origin: center;
  }
  50% {
    transform: translateY(14px) scale(1);
    transform-origin: center;
  }
  100% {
    transform: translateY(0px) scale(1);
    transform-origin: center;
  }
}
@keyframes index_bubble-2i-Hs {
  0% {
    transform: translateY(0px) scale(1);
    transform-origin: center;
  }
  50% {
    transform: translateY(14px) scale(1);
    transform-origin: center;
  }
  100% {
    transform: translateY(0px) scale(1);
    transform-origin: center;
  }
}
</style>
>
