<template>
  <v-footer
    class="d-flex justify-center"
    style="
      padding-bottom: 20px;
      background-image: linear-gradient(
        to right,
        rgb(48, 22, 88),
        rgb(48, 39, 131)
      );
    "
  >
    <v-row style="margin:0 10% 20px 10%;border-top: 1px solid white;">
      <v-col cols="6" class="d-flex flex-column text-left">
        <v-img
          :src="require('../assets/index-logo.png')"
          contain
          max-width="10rem"
          style="margin-top: 2rem"
        />
        <div class="align-self-start">
           <a
          class="text-white beian"
          src="javascript(void(0))"
          @click="
            open = false;
            $router.push('/AIinstitute/');
          "
          >Copyright © 2023 杭州智海知光科技有限公司</a>
        <a
          href="http://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer external nofollow"
          style="color: white;font-size: 0.8rem;"
          > 浙ICP备20025001号-2</a>
        </div>
      </v-col>
      <v-col cols="6" class="flex-column text-right">
        <div
          class="footer-heading"
          style="font-size:28px; margin-top: 2rem; color: white"
        >
          联系我们
        </div>
        <div
          class="footer-sub-heading"
          style="font-size: 14.45px; color: white"
        >
          Contact us
        </div>
          <v-btn
            href="mailto:contact@wiscean.cn"
            color="white"
            icon="mdi-email"
            style=" margin-top: 12px"
          ></v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "IndexFooter",
  data() {
    return {
      icons: ["mdi-wechat", "mdi-phone", "mdi-email"],
    };
  },
};
</script>

<style>
.footer-logo {
  width: 80px;
}
.footer-logo img {
  width: 100%;
}

.beian {
  margin-top: 4rem;
  font-size: 0.8rem;
  color: #ffffff;
  margin-bottom: 2rem;
}

.beian a {
  text-decoration: blink;
  display: inline-block;
  word-break: keep-all;
  margin-bottom: 0.5rem;
  /* margin-left: 1rem; */
}
</style>
