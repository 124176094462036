import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()
router.beforeEach((to, from, next) => {    
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  to.meta.title?document.title = to.meta.title:document.title ='智海知光'
  next()
})

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
