<template>
  <div>
    <v-carousel
      style="
        background-image: linear-gradient(
          to bottom right,
          rgb(48, 22, 88),
          rgb(48, 39, 131)
        );
        white-space: pre-wrap;
      "
      :show-arrows="false"
      hide-delimiters
      height="94vh"
    >
      <v-carousel-item src="../assets/img/homepage-background-img-1.png" cover>
        <v-img
          :width="300"
          src="../assets/img/index-logo.png"
          style="left: 6vw; top: 16vh"
        ></v-img>
        <div
          style="
            font-size: 16px;
            color: white;
            margin-left: 6vw;
            margin-right: 6vw;
            margin-top: 30vh;
            line-height: 36px;
            max-width:700px;
          "
        >
        <P v-html="intro"></P>
          <!-- <div>
            <v-btn
              color="white"
              style="margin-top: 20px"
              @click="
                open = false;
                $router.push('/AIinstitute/');
              "
            >
              了解更多
            </v-btn>
          </div> -->
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "IndexBanner",
  data() {
    return {
      bgUrl: require("../assets/img/homepage-background-img.png"),
      intro:
        "智海知光依托浙江大学人工智能研究所，由浙江大学朱强教授<a href='https://person.zju.edu.cn/zhuq' target='_blank' style='color: white; text-decoration: none'>（https://person.zju.edu.cn/zhuq）</a>领军主导，以科技创新和人才培育为使命，深度聚焦人工智能生态建设，面向科技企业，通过汇聚国内外高校和产业资源，搭建产学研互通的全新生态链，推动人工智能领域使能技术、赋能社会、连接塑造生态。"
    };
  },
};
</script>

<style scoped></style>
