<template>
  <v-app-bar
    v-if="!isMobile"
    style="
      background-image: linear-gradient(
        to right,
        rgb(48, 22, 88),
        rgb(48, 39, 131)
      );
    "
    :elevation="4"
  >
    <v-toolbar-title @click="go('../FileList')"
      ><v-img
        :src="require('../assets/index-logo.png')"
        class="justify-start"
        style="margin-left: 0px"
        contain
        width="100px"
      />
    </v-toolbar-title>

    <row style="margin-right: 8%">
      <v-menu
        v-for="(item, index) in nav"
        :key="index"
        open-on-hover
        transition="slide-y-transition"
        location="bottom"
      >
        <template v-slot:activator="{ props }">
          <v-btn color="white" v-bind="props" :to="item.router" width="128px">
            {{ item.title }}
          </v-btn>
        </template>

        <v-list v-if="item.subItems">
          <v-list-item
            v-for="(item2, index2) in item.subItems"
            :key="index2"
            :to="item.router"
          >
            <v-list-item-title>{{ item2.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon="mdi-magnify" style="margin-left: 24px; color: white"></v-btn>
    </row>
  </v-app-bar>

  <v-app-bar
    v-if="isMobile"
    style="
      color: white;
      background-image: linear-gradient(
        to right,
        rgb(48, 22, 88),
        rgb(48, 39, 131)
      );
    "
    prominent
  >
    <v-toolbar-title @click="go('../FileList')"
      ><v-img
        :src="require('../assets/index-logo.png')"
        class="justify-start"
        style="margin-left: 0px"
        contain
        width="100px"
      />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon
      variant="text"
      @click.stop="drawer = !drawer"
    ></v-app-bar-nav-icon>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" location="top" temporary>
    <v-list>
      <v-list-item
        v-for="(item, index) in nav"
        :key="index"
        :to="item.router"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  name: "IndexHeader",
  data() {
    return {
      drawer: false,
      isMobile: false,
      nav: [
        {
          title: "首页",
          router: "../",
        },
        {
          title: "生态联结",
          router: "../Ecological",
        },
        {
          title: "资源共建",
          router: "../ResourceConstruction",
        },
        {
          title: "技术赋能",
          router: "../TechnologyEmpowerment",
        },
        {
          title: "关于",
          router: "../About",
        },
      ],
    };
  },
  mounted() {
    const { name } = useDisplay();
    console.log(name.value);
    this.isMobile = name.value == "xs";
  },
};
</script>

<style scoped>
</style>

